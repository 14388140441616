export const ArrayRange = (start, stop, step) =>
    Array.from({ length: (stop - start) / step + 1 },
        (value, index) => start + index * step
    );

export const getPosition = (x, arr) => {
    let length = arr.length
    let res = [-1, -1]
    let find = arr.indexOf(x)
    if (find >= 0) {
        if (find == 0) {
            res[0] = arr[find]
        } else {
            res[0] = arr[find - 1]
        }
        if (find + 1 == length) {
            res[1] = -1
        } else {
            res[1] = arr[find + 1]
        }
    }
    return res
}

export const sortNumbers = (numbers) => {
    numbers.sort((a, b) => {
        return a - b
    })
    return numbers
}

export const generateData = (from, to) => {
    let return_array = []
    ArrayRange(from, to, 1).forEach((element) => return_array.push({
        col_0: element,
        col_1: `Владелец Кабанбаев Е.А.`,
        col_2: 1,
        col_3: `Владелец`,
        col_4: 2,
        col_5: "",
        col_6: `Эн`,
        col_7: `8-701-238-50`,
        col_8: "",
        col_9: "",
        col_10: "",
    }));
    return return_array
}

export default generateData