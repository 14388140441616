<template>
  <v-app>
    <v-main>
      <v-container fluid class="main-container">
        <component :is="componentToShow" />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import test_header from "@/assets/test_header.json"
// import test_CustomHeader from "@/assets/test_Customheader.json"
// import test_data from "@/assets/test_data.json"
import { mapGetters } from "vuex";
import { generateData } from "./func/help_func";
export default {
  name: "App",
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "tableHeader",
      "headerList",
      "dataList",
      "customHeaderList",
      "totalPage",
      "debug",
    ]),
    componentToShow() {
      if (
        this.tableHeader != null &&
        this.headerList != null &&
        this.dataList != null &&
        this.totalPage != null
      ) {
        return () => import("@/components/table/Table.vue");
      } else return false;
    },
  },
  methods: {
    test() {
      this.$store.commit("setLiteMode", false);
      this.$store.commit("setTransIDMode", true);
      // this.$store.commit("setSingleSelect", true);
      // this.$store.commit("setUtverdit", false);
      // this.$store.commit("setUtverditKey", "col_4");

      this.$store.commit("setLiteSearch", true);
      // this.$store.commit("setUtverditTrueValues",[""])
      this.$store.commit("setReadonly", false);
      this.$store.commit("setAllMode", false);
      // this.$store.commit("setEditMode", true);
      // this.$store.commit("setDeleteMode", true);

      // this.$store.commit("setColorTrueValues", ["выполнено"]);
      // this.$store.commit("setColorMode", false);
      // this.$store.commit("setColorKey", "col_4");
      // this.$store.commit("setColor", "green white-text");

      this.$store.commit(
        "setTableHeader",
        "Нормативы плотности теплового потока трубопроводов при общей продолжительности работы в год более 5000 ч, Вт/м [ккал/(м . ч)] "
      );
      this.$store.commit("setTotalPage", 10000);
      this.$store.commit("setDataList", generateData(0,10000));
      this.$store.commit("setHeaderList", test_header.headerList);
      // this.$store.commit("setDataList", test_data.dataList);
      // this.$store.commit("setViewData", dataList);
      // this.$store.commit("setSelectedPages",1)
      // console.log(test_CustomHeader);
      // this.$store.commit("setCustomHeaderList", test_CustomHeader);
      // this.$store.commit("setReadonly", false);
      // this.getDataFromApi(this.filledData(100), this.newPage, this.oldPage);
    },
  },
  mounted() {
    console.log("Mounted APP");
    window.MainComponent = this;
    this.$nextTick(function () {
      window.chrome.webview.postMessage("OnPageFinished");
    });
    this.$store.commit("setDebug",true);
    if (this.debug) {
      this.test();
    }
  },
  created() {
    console.log("Created APP");
  },
};
</script>
<style scoped>
.main-container {
  padding: 0;
}
</style>