export default {
    actions: {
        async validateContextOptions({ state }) {
            if (!state.moveToMode) {
                let res = state.contextOptions.find((el) => el.slug == 'moveto')
                res.class = 'disabled'
            }
            if (!state.editMode) {
                let res = state.contextOptions.find((el) => el.slug == 'edit')
                res.class = 'disabled'
                    // commit('setEditMode', false)
            }
            if (!state.deleteMode) {
                let res = state.contextOptions.find((el) => el.slug == 'delete')
                res.class = 'disabled'
                    // commit('setDeleteMode', false)
            }
        },
        async getEditHeader({ state, commit }) {
            let find_key = ['value', 'key', 'type', 'name']

            let theadMap = {}
            let editThead = {}
            if (state.customHeaderList)
                for (const [, value] of Object.entries(state.customHeaderList)) {
                    value.forEach((el) => {
                        if (Object.keys(el).includes(...find_key)) {
                            theadMap[el.key] = {
                                name: el.name === undefined ? 'Наименование не заполнено' : el.name,
                                type: el.type
                            };
                            editThead[el.key] = el.value
                        }
                    })
                    commit('setHeaderEditFields', editThead)
                    commit('setTheadMap', theadMap)
                }

        },
    },
    mutations: {
        setFormat(state, value) {
            state.format = value
        },
        setTableHeader(state, value) {
            state.tableHeader = value
        },
        setHeaderList(state, value) {
            state.headerList = value
        },
        setDataList(state, value) {
            state.dataList = value
        },
        setTempDataList(state, value) {
            state.tempDataList = value
        },
        setCustomHeaderList(state, value) {
            state.customHeaderList = value
        },
        setTotalPage(state, value) {
            state.totalPage = value
        },
        setDebug(state, value) {
            state.debug = value
        },
        setPage(state, value) {
            state.page = value
        },
        setSelectedPages(state, value) {
            state.selectedPages.push(value)
        },
        setInitSelectedPages(state, value) {
            state.selectedPages = value
        },
        setItemsPerPage(state, value) {
            state.itemsPerPage = value
        },
        setFontSize(state, value) {
            state.format.fontSize = value
        },
        setWidthCustom(state, value) {
            state.format.widthCustom = value
        },
        setHeadersSelected(state, value) {
            state.headersSelected = value
        },
        setSearch(state, value) {
            state.search = value
        },
        setDense(state, value) {
            state.format.dense = value
        },
        setTempPage(state, value) {
            state.tempPage = value
        },
        setReadonly(state, value) {
            state.readonly = value
            state.deleteMode = !value
            state.editMode = !value
        },
        setContextOptions(state, value) {
            state.contextOptions = value
        },
        setAddMode(state, value) {
            state.addMode = value
        },
        setEditMode(state, value) {
            state.editMode = value
        },
        setDeleteMode(state, value) {
            state.deleteMode = value
        },
        setMoveMode(state, value) {
            state.moveToMode = value
        },
        setCurrentDataList(state, value) {
            state.currentDataList = value
        },
        setTechnicalCondition(state, value) {
            state.technicalCondition = value
        },
        setHeightTable(state, value) {
            state.heightTable = value
        },
        setLiteMode(state, value) {
            state.liteMode = value
        },
        setColorMode(state, value) {
            state.colorMode = value
        },
        setColorKey(state, value) {
            state.colorKey = value
        },
        setColor(state, value) {
            state.color = value
        },
        setTransIDMode(state, value) {
            state.transIDMode = value
        },
        setColorTrueValues(state, value) {
            state.colorTrueValues = [...state.colorTrueValues, ...value]
        },
        setSingleSelect(state, value) {
            state.singleSelect = value
        },
        setMobileBreakpoint(state, value) {
            state.mobileBreakpoint = value
        },
        setUtverdit(state, value) {
            state.utverdit = value
        },
        setUtverditKey(state, value) {
            state.utverditKey = value
        },
        setUtverditTrueValues(state, value) {
            state.utverditTrueValues = [...state.utverditTrueValues, ...value]
        },
        setGrouping(state, value) {
            state.grouping = value
        },
        setLiteSearch(state, value) {
            state.liteSearch = value
        },
        setHeaderEditFields(state, value) {
            state.headerEditFields = value
        },
        setTheadMap(state, value) {
            state.theadMap = value
        },
        setDisablePagination(state, value) {
            state.disablePagination = value
        },
        setSearchMode(state, value) {
            state.searchMode = value
        },
        setViewData(state, value) {
            state.viewData = value
        },
        setAllMode(state, value) {
            state.allMode = value
        },
        setTableShow(state, value) {
            state.tableShow = value
        },
        setRow(state, value) {
            state.row = value
        }

    },
    state: {
        row: 25,
        tableShow: true,
        liteSearch: false,
        disablePagination: false,
        grouping: null,
        transIDMode: false,
        colorMode: false,
        colorKey: 'col_0',
        colorTrueValues: ['осмотрено', 1, true],
        color: 'green',
        singleSelect: false,
        utverdit: false,
        utverditKey: false,
        utverditTrueValues: ['утверждено', 'утвержденный'],
        tempPage: 1,
        heightTable: '67vh',
        mobileBreakpoint: 800,
        readonly: false,
        technicalCondition: false,
        allMode: false,
        addMode: true,
        editMode: true,
        deleteMode: true,
        moveToMode: true,
        liteMode: false,
        search: "",
        searchMode: false,
        debug: false,
        tableHeader: null,
        headerList: null,
        viewData: [],
        dataList: null,
        tempDataList: null,
        customHeaderList: null,
        headerEditFields: null,
        theadMap: null,
        totalPage: null,
        page: 1,
        selectedPages: [],
        itemsPerPage: 100,
        currentDataList: [],
        contextOptions: [{
                name: "Перейти",
                slug: "moveto",
                class: ""
            },
            {
                type: "divider",
            },
            {
                name: "Изменить",
                slug: "edit",
                class: ""
            },
            {
                name: "Удалить",
                slug: "delete",
                class: ""
            },
        ],
        format: {
            fontSize: 12,
            widthCustom: "auto",
            dense: true
        },
        headersSelected: []
    },
    getters: {
        row(state) {
            return state.row
        },
        tableShow(state) {
            return state.tableShow
        },
        allMode(state) {
            return state.allMode
        },
        searchMode(state) {
            return state.searchMode
        },
        disablePagination(state) {
            return state.disablePagination
        },
        theadMap(state) {
            return state.theadMap
        },
        headerEditFields(state) {
            return state.headerEditFields
        },
        liteSearch(state) {
            return state.liteSearch
        },
        grouping(state) {
            return state.grouping
        },
        utverditKey(state) {
            return state.utverditKey
        },
        utverdit(state) {
            return state.utverdit
        },
        utverditTrueValues(state) {
            return state.utverditTrueValues
        },
        mobileBreakpoint(state) {
            return state.mobileBreakpoint
        },
        singleSelect(state) {
            return state.singleSelect
        },
        colorTrueValues(state) {
            return state.colorTrueValues
        },
        transIDMode(state) {
            return state.transIDMode
        },
        colorMode(state) {
            return state.colorMode
        },
        colorKey(state) {
            return state.colorKey
        },
        color(state) {
            return state.color
        },
        liteMode(state) {
            return state.liteMode
        },
        heightTable(state) {
            return state.heightTable
        },
        format(state) {
            return state.format
        },
        technicalCondition(state) {
            return state.technicalCondition
        },
        currentDataList(state) {
            return state.currentDataList
        },
        addMode(state) {
            return state.addMode
        },
        editMode(state) {
            return state.editMode
        },
        deleteMode(state) {
            return state.deleteMode
        },
        moveToMode(state) {
            return state.moveToMode
        },
        contextOptions(state) {
            return state.contextOptions
        },
        readonly(state) {
            return state.readonly
        },
        tempPage(state) {
            return state.tempPage
        },
        search(state) {
            return state.search
        },
        dense(state) {
            return state.format.dense
        },
        headersSelected(state) {
            return state.headersSelected
        },
        fontSize(state) {
            return state.format.fontSize
        },
        widthCustom(state) {
            return state.format.widthCustom
        },
        debug(state) {
            return state.debug
        },
        tableHeader(state) {
            return state.tableHeader
        },
        headerList(state) {
            return state.headerList
        },
        customHeaderList(state) {
            return state.customHeaderList
        },
        dataList(state) {
            return state.dataList
        },
        tempDataList(state) {
            return state.tempDataList
        },
        totalPage(state) {
            return state.totalPage
        },
        page(state) {
            return state.page
        },
        selectedPages(state) {
            return state.selectedPages
        },
        itemsPerPage(state) {
            return state.itemsPerPage
        },
        viewData(state) {
            return state.viewData
        }
    },
}