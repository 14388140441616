import Vue from 'vue'
import store from './store'
import App from './App.vue'
import vuetify from './plugins/vuetify'

//context-menu 
import "vue-simple-context-menu/dist/vue-simple-context-menu.css";
import VueSimpleContextMenu from "vue-simple-context-menu";
Vue.component("vue-simple-context-menu", VueSimpleContextMenu);
//END

//toasted
import Toasted from 'vue-toasted';
Vue.use(Toasted)
    //END
Vue.config.productionTip = false

new Vue({
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')